import * as React from "react";
import Header from "../components/Header";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

import "../scss/style.scss";
import Footer from "../components/Footer";
import ServicePage from "../components/services/ServicePage";
import Banner from "../components/Banner";

// markup
const IndexPage = () => {
  return (
    <main className="page">
      <title>
        Société de sécurité spécialisée dans votre protection - Mentions Légales
      </title>
      <div id="wrapper" className="wrapper">
        <Header />
        <Banner
        title="Mentions Légales"
        content="Notre catalogue de services et prestations"
        position="left"
        />
       <div className="container p-2">
            <div className="p-2">
              <h1>Informations légales</h1>
              <p>
                Le site web https://am-securite.fr/ est édité par Amine RAHMANE, 
                micro-entrepreneur, dont le siège social est
                situé 95 rue des Trois Territoires, 94120 FONTENAY-SOUS-BOIS et dont le contact est rahmane.amine@gmail.com.
              </p>
              <p>
                Hébergeur : Netlify,  44 Montgomery Street, Suite 300, San Francisco, California 94104, support@netlify.com
              </p>
            </div>
            <div className="p-2">
              <h1>Propriété intellectuelle</h1>
              <p>
                Le site web https://am-securite.fr/ et chacun des éléments
                qui le compose (notamment les textes, photographies, images,
                illustrations, logos, marques, etc.) sont protégés au titre de
                la propriété intellectuelle. Toute reproduction, représentation,
                utilisation ou adaptation, sous quelque forme que ce soit, de
                tout ou partie de ces éléments, sans l&apos;accord préalable et écrit
                de Amine RAHMANE, est strictement interdite.
              </p>
            </div>
            <div className="p-2">
              <h1>Données personnelles</h1>
              <p>
                Les données personnelles collectées sur ce site web sont
                destinées à Amine RAHMANE et sont utilisées à des fins de
                gestion de la clientèle et de prospection commerciale.
                Conformément à la loi &quot;Informatique et Libertés&quot; du 6 janvier
                1978 modifiée et au Règlement Général sur la Protection des
                Données (RGPD), vous disposez d&apos;un droit d&apos;accès, de
                rectification, de suppression et d&apos;opposition aux données
                personnelles vous concernant. Pour exercer ces droits, veuillez
                contacter Amine RAHMANE.
              </p>
            </div>
            <div className="p-2">
              <h1>Cookies</h1>
              <p>
                Le site web https://am-securite.fr/ utilise des cookies afin
                d&apos;améliorer votre expérience utilisateur. Vous pouvez à tout
                moment désactiver l&apos;utilisation de cookies en modifiant les
                paramètres de votre navigateur web.
              </p>
            </div>
            <div className="p-2">
              <h1>Responsabilité</h1>
              <p>
                Amine RAHMANE décline toute responsabilité quant à
                l&apos;utilisation qui pourrait être faite du contenu du site web
                https://am-securite.fr/.
              </p>
            </div>
            <div className="p-2">
              <h1>
                Loi applicable et juridiction compétente
              </h1>
              <p>
                Les présentes mentions légales sont soumises au droit français.
                Tout litige relatif à l&apos;interprétation ou à l&apos;exécution des
                présentes mentions légales sera de la compétence exclusive des
                tribunaux français.
              </p>
            </div>
          </div>
      </div>
      <Footer />
    </main>
  );
};

export default IndexPage;
